import { DateRangePickerType } from "constants/enum";
import { isUndefined } from "lodash";
import moment, { Moment } from "moment";
import { convertToFullDate } from "./format";

export const handleFormData = ({
  params,
  nestedFieldName,
}: {
  params: any;
  nestedFieldName: string;
}) => {
  const formData = new FormData();
  Object.keys(params).forEach((paramItem) => {
    if (paramItem === nestedFieldName) {
      Object.keys(params[nestedFieldName]).forEach((paramProfileItem) => {
        if (!isUndefined((params[nestedFieldName] as any)[paramProfileItem])) {
          formData.append(
            `${nestedFieldName}[${paramProfileItem}]`,
            (params[nestedFieldName] as any)[paramProfileItem]
          );
        }
      });
    } else {
      formData.append(paramItem, (params as any)[paramItem]);
    }
  });
  return formData;
};

export const isFileTooLarge = (file?: File) => {
  return file && file?.size > 5e6;
};

export const resetFormFields = ({ formFromProps }: any) => {
  return [
    { name: "username", value: formFromProps?.username || "" },
    { name: "password", value: "" },
    {
      name: ["streamer_profile_attributes", "address"],
      value: formFromProps?.profile?.address || "",
    },
    {
      name: ["streamer_profile_attributes", "age"],
      value: formFromProps?.profile?.age
        ? moment(formFromProps?.profile?.age)
        : "",
    },
    {
      name: ["streamer_profile_attributes", "area"],
      value: formFromProps?.profile?.area || "",
    },
    {
      name: ["streamer_profile_attributes", "audience_limit"],
      value: formFromProps?.profile?.audience_limit || "3000",
    },
    {
      name: ["streamer_profile_attributes", "avatar"],
      value: formFromProps?.profile?.avatar || "",
    },
    {
      name: ["streamer_profile_attributes", "background"],
      value: formFromProps?.profile?.background || "",
    },
    {
      name: ["streamer_profile_attributes", "blood_type"],
      value: formFromProps?.profile?.blood_type || "",
    },
    {
      name: ["streamer_profile_attributes", "career"],
      value: formFromProps?.profile?.career || "",
    },
    {
      name: ["streamer_profile_attributes", "character"],
      value: formFromProps?.profile?.character || "",
    },
    {
      name: ["streamer_profile_attributes", "comment"],
      value: formFromProps?.profile?.comment || "",
    },
    {
      name: ["streamer_profile_attributes", "feature"],
      value: formFromProps?.profile?.feature || "",
    },
    {
      name: ["streamer_profile_attributes", "height"],
      value: formFromProps?.profile?.height || "",
    },
    {
      name: ["streamer_profile_attributes", "hobby"],
      value: formFromProps?.profile?.hobby || "",
    },
    {
      name: ["streamer_profile_attributes", "name"],
      value: formFromProps?.profile?.name || "",
    },
    {
      name: ["streamer_profile_attributes", "streamer_type"],
      value: formFromProps?.profile?.streamer_type || "",
    },
    {
      name: ["streamer_profile_attributes", "three_size"],
      value: formFromProps?.profile?.three_size || "",
    },
  ];
};

export const getFromToDate = ({
  date,
  rangePickerType = DateRangePickerType.MONTH,
}: {
  date?: Moment | null;
  rangePickerType?: DateRangePickerType;
}) => {
  if (date) {
    if (rangePickerType === DateRangePickerType.WEEK) {
      const yearNumber = date?.year();
      const weekNumber = date?.week();
      const fromDate = moment()
        .year(yearNumber || 0)
        .week(weekNumber || 0)
        .day("sunday");
      const toDate = moment(fromDate).add(6, "d");
      return {
        from: convertToFullDate(fromDate),
        to: convertToFullDate(toDate),
      };
    }
    const startOfMonth = convertToFullDate(moment(date).startOf("month"));
    const endOfMonth = convertToFullDate(moment(date).endOf("month"));
    return {
      from: startOfMonth,
      to: endOfMonth,
    };
  }
  return {
    from: undefined,
    to: undefined,
  };
};
