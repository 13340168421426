import { sendGet, sendPost, sendPut } from "./axios";

// eslint-disable-next-line import/prefer-default-export
export const getListAccountsAPI = (params: any) =>
  sendGet("/cms/streamers", params);
export const addAccountAPI = (params: any) =>
  sendPost("/cms/streamers", params);
export const editAccountAPI = ({
  accountId,
  params,
}: {
  accountId: number;
  params: any;
}) => sendPut(`/cms/streamers/${accountId}`, params);
export const editStatusAccountAPI = (params: any) =>
  sendPut(`/cms/streamers/${params.id}/update-status`, params);
export const editChangePasswordAccountAPI = (params: any) =>
  sendPut(`/cms/streamers/${params.id}/change-password`, params);
