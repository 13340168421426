import styles from "./styles.module.scss";
import { Menu, Dropdown, Select } from "antd";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import { logout } from "utils/helper/authentication";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { LanguageType } from "constants/enum";
import useLanguageFlag from "utils/hooks/useLanguageFlag";
import { ILanguageFlag } from "constants/interface";
import { useEffect, useState } from "react";

interface IProps {
  isPageLogin?: boolean;
}

const { Option } = Select;

export default function PageHeader({ isPageLogin }: IProps) {
  const { t } = useTranslation();
  const { toggleSideNav } = useToggleSideNav();
  const currentKey = localStorage.getItem("i18nextLng");

  const [currentFlag, setCurrentFlag] = useState();

  const { languageFlags, fetchingLanguageFlags } = useLanguageFlag();

  useEffect(() => {
    if (languageFlags) {
      setCurrentFlag(getCurrentFlag(currentKey));
    }
  }, [fetchingLanguageFlags]);

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={logout}>
        {t("common.logout")}
      </Menu.Item>
    </Menu>
  );

  const handleSelectLanguageChange = (value: any) => {
    setCurrentFlag(getCurrentFlag(value));
    i18next.changeLanguage(value);
  };
  const getCurrentFlag = (key: string | null) => {
    if (!key) return null;
    return languageFlags?.data?.filter(
      (lf: ILanguageFlag) => lf.code === key
    )[0]?.icon;
  };
  const renderDisplayLang = (languageData: ILanguageFlag) => {
    return languageData.code === "ja"
      ? languageData.ja_name
      : languageData.eng_name;
  };

  return (
    <div className={styles.headerWrapper}>
      {isPageLogin ? (
        <div className={styles.webName}>{t("common.webName")}</div>
      ) : (
        <svg
          height="32"
          width="32"
          style={{ cursor: "pointer" }}
          onClick={toggleSideNav}
        >
          <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
        </svg>
      )}
      <div className={styles.option}>
        <div className={styles.language}>
          {currentFlag && <img src={currentFlag} alt="Flag icon" />}
          <Select
            className={styles.language__select}
            placeholder={
              <div className={styles.placeholder}>
                {t("placeholder.selectLanguage")}
              </div>
            }
            onChange={handleSelectLanguageChange}
          >
            {languageFlags?.data?.map((lf: ILanguageFlag) => (
              <Option value={lf.code} key={lf.id}>
                {renderDisplayLang(lf)}
              </Option>
            ))}
            <Option value={LanguageType.KEY}>Show key</Option>
          </Select>
        </div>
        {!isPageLogin && (
          <div className={styles.menuWrapper}>
            <div className={styles.menuItem}>
              <Dropdown overlay={menu} trigger={["click"]}>
                <div>
                  <span>{t("header.hiAdmin")}</span>
                  &nbsp;
                </div>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
