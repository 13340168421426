import { StatusType } from "./enum";

const FakeData = {
  audienceLimitArr: Array(3000)
    .fill("")
    .filter((_, initIndex) => (initIndex + 1) % 50 === 0)
    .map((_, initIndex) => (initIndex + 1) * 50),
  bloodTypeArr: ["O", "A", "B", "AB"],
  statusArr: [
    {
      valueApi: StatusType.All,
      valueDisplayKey: "accounts.statusAll",
    },
    {
      valueApi: StatusType.Active,
      valueDisplayKey: "accounts.statusActive",
    },
    {
      valueApi: StatusType.Inactive,
      valueDisplayKey: "accounts.statusInactive",
    },
  ],
  colorsArr: [
    "white",
    "black",
    "red",
    "blue",
    "yellow",
    "pink",
    "grey",
    "green",
    "brown",
  ],
};

export default FakeData;
