/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { addAccountAPI, editAccountAPI } from "api/accounts";
import Uploader from "components/Uploader";
import { UploaderType } from "constants/enum";
import { handleSuccessMessage } from "i18n";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RuleForm } from "utils/helper/ruleForms";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";
import FakeData from "constants/data";
import moment from "moment";
import {
  handleFormData,
  isFileTooLarge,
  resetFormFields,
} from "utils/helper/processData";
import { IAccountParamsEditCreate } from "constants/interface";
import LoadingApi from "components/LoadingApi";
import { convertToFullDate } from "utils/helper/format";

const { Option } = Select;
const { Text } = Typography;

interface Props {
  formFromProps?: IAccountParamsEditCreate;
  isModalVisible: boolean;
  handleCancel(): void;
  handleOk(): void;
}

const initStateLocalFile = {
  avatar: undefined,
  background: undefined,
};

const AddEditAccountModal = (props: Props) => {
  const { isModalVisible, handleCancel, handleOk, formFromProps } = props;
  const { t } = useTranslation();
  const [temp, setTemp] = useState(0);
  const [currentLocalFile, setCurrentLocalFile] = useState<{
    avatar?: File;
    background?: File;
  }>(initStateLocalFile);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const form = Form.useForm<any>()[0];

  /**
   * Process files and data
   * */
  const handleSubmit = useCallback(
    async (payload: any) => {
      try {
        setIsLoadingApi(true);
        const params = _.pick(payload, [
          "username",
          "password",
          "streamer_profile_attributes",
        ]);
        const paramsProcessed = {
          ...params,
          streamer_profile_attributes: {
            ...params.streamer_profile_attributes,
            age: params.streamer_profile_attributes.age
              ? convertToFullDate(
                  moment(params.streamer_profile_attributes.age)
                )
              : "",
            avatar: currentLocalFile?.avatar,
            background: currentLocalFile?.background,
          },
        };
        const formData = handleFormData({
          params: paramsProcessed,
          nestedFieldName: "streamer_profile_attributes",
        });
        if (formFromProps) {
          formData.append(
            "streamer_profile_attributes[id]",
            String(formFromProps?.profile?.id)
          );
          await editAccountAPI({
            accountId: Number(formFromProps?.id || -1),
            params: formData,
          });
          handleSuccessMessage(t("form.editAccountSuccess"));
        } else {
          await addAccountAPI(formData);
          handleSuccessMessage(t("form.addAccountSuccess"));
        }
        form.resetFields();
        handleOk();
      } finally {
        setIsLoadingApi(false);
      }
    },
    [formFromProps, currentLocalFile]
  );

  /**
   * Rendering
   * */
  const renderUploaderAva = useCallback(() => {
    return (
      <div style={{ width: "40%" }}>
        <Uploader
          type={UploaderType.UPLOAD_PHOTO}
          handleChange={({ url, file }) => {
            form.setFields([
              { name: ["streamer_profile_attributes", "avatar"], value: url },
            ]);
            setCurrentLocalFile({
              ...currentLocalFile,
              avatar: file,
            });
          }}
        >
          {form.getFieldValue(["streamer_profile_attributes", "avatar"]) ? (
            <>
              <div className={styles.btnAvatarBackdrop}>
                <UploadOutlined style={{ color: "white", fontSize: 40 }} />
              </div>
              <img
                src={form.getFieldValue([
                  "streamer_profile_attributes",
                  "avatar",
                ])}
                alt="profile_ava"
                className={styles.btnAvatar}
              />
            </>
          ) : (
            <Button
              shape="circle"
              icon={<UploadOutlined />}
              size={"large"}
              className={styles.btnAvatar}
            >
              {t("form.avatar")}
            </Button>
          )}
        </Uploader>
        {isFileTooLarge(currentLocalFile?.avatar) && (
          <Text type="danger" style={{ marginBottom: 24 }}>
            {t("validate.avatarTooLarge")}
          </Text>
        )}
      </div>
    );
  }, [
    form.getFieldValue(["streamer_profile_attributes", "avatar"]),
    currentLocalFile?.avatar,
    form.getFieldValue(["streamer_profile_attributes", "background"]),
    currentLocalFile?.background,
  ]);

  const renderUploaderBackground = useCallback(() => {
    return (
      <div style={{ width: "40%" }}>
        <Uploader
          aspect={300 / 150}
          type={UploaderType.UPLOAD_PHOTO}
          handleChange={({ url, file }) => {
            form.setFields([
              {
                name: ["streamer_profile_attributes", "background"],
                value: url,
              },
            ]);
            setCurrentLocalFile({
              ...currentLocalFile,
              background: file,
            });
          }}
        >
          {form.getFieldValue(["streamer_profile_attributes", "background"]) ? (
            <>
              <div className={styles.btnBackgroundBackdrop}>
                <UploadOutlined style={{ color: "white", fontSize: 40 }} />
              </div>
              <img
                src={form.getFieldValue([
                  "streamer_profile_attributes",
                  "background",
                ])}
                alt="profile_background"
                className={styles.btnBackground}
              />
            </>
          ) : (
            <Button
              icon={<UploadOutlined />}
              size={"large"}
              className={styles.btnBackground}
            >
              {t("form.background")}
            </Button>
          )}
        </Uploader>
        {isFileTooLarge(currentLocalFile?.background) && (
          <Text type="danger" style={{ marginBottom: 24, marginTop: 24 }}>
            {t("validate.backgroundTooLarge")}
          </Text>
        )}
      </div>
    );
  }, [
    form.getFieldValue(["streamer_profile_attributes", "avatar"]),
    currentLocalFile?.avatar,
    form.getFieldValue(["streamer_profile_attributes", "background"]),
    currentLocalFile?.background,
  ]);

  /**
   * UseEffect
   * */
  useEffect(() => {
    form.setFields(resetFormFields({ formFromProps }));
    setTemp(temp + 1);
  }, [formFromProps]);

  return (
    <Modal
      title={t(
        formFromProps ? "modal.editAccountTile" : "modal.addAccountTitle"
      )}
      visible={isModalVisible}
      onCancel={() => {
        setCurrentLocalFile(initStateLocalFile);
        form.setFields(resetFormFields({ formFromProps }));
        handleCancel();
      }}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
      width={"80%"}
    >
      <LoadingApi isLoading={isLoadingApi} />
      <Form form={form} onFinish={handleSubmit}>
        <div className={styles.btnAvatarBackgroundWrapper}>
          {renderUploaderAva()}
          {renderUploaderBackground()}
        </div>
        <Row>
          <Col span={10}>
            <Form.Item
              name={["streamer_profile_attributes", "avatar"]}
              noStyle
            />
            <Form.Item
              name={["streamer_profile_attributes", "background"]}
              noStyle
            />
            <Form.Item
              validateFirst
              label={t("form.email")}
              name="username"
              rules={RuleForm.email()}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            {!formFromProps && (
              <Form.Item
                validateFirst
                label={t("form.password")}
                name="password"
                rules={RuleForm.password()}
                labelAlign="left"
                labelCol={{ span: 8 }}
                normalize={(value) => value.trim()}
              >
                <Input.Password />
              </Form.Item>
            )}
            <Form.Item
              validateFirst
              label={t("form.name")}
              name={["streamer_profile_attributes", "name"]}
              rules={RuleForm.name()}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              validateFirst
              label={t("form.audience_limit")}
              name={["streamer_profile_attributes", "audience_limit"]}
              rules={RuleForm.audienceLimit()}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Select style={{ width: "100%" }}>
                {FakeData.audienceLimitArr.map((audItem, audIndex) => (
                  <Option key={String(audIndex)} value={String(audItem)}>
                    {String(audItem)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("form.address")}
              name={["streamer_profile_attributes", "address"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.age")}
              name={["streamer_profile_attributes", "age"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <DatePicker
                value={
                  form.getFieldsValue(["streamer_profile_attributes"])
                    ?.streamer_profile_attributes?.age || ""
                }
                inputReadOnly
                style={{ width: "100%" }}
                disabledDate={(date) =>
                  date.isAfter(moment().add(1, "day").format())
                }
                placeholder={t("placeholder.dateOfBirthPicker")}
              />
            </Form.Item>

            <Form.Item
              label={t("form.blood_type")}
              name={["streamer_profile_attributes", "blood_type"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Select style={{ width: "100%" }}>
                {FakeData.bloodTypeArr.map((blItem, blIndex) => (
                  <Option key={String(blIndex)} value={String(blItem)}>
                    {String(blItem)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("form.career")}
              name={["streamer_profile_attributes", "career"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={3} span={11}>
            <Form.Item
              label={t("form.character")}
              name={["streamer_profile_attributes", "character"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.feature")}
              name={["streamer_profile_attributes", "feature"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.height")}
              name={["streamer_profile_attributes", "height"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.hobby")}
              name={["streamer_profile_attributes", "hobby"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.streamer_type")}
              name={["streamer_profile_attributes", "streamer_type"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.three_size")}
              name={["streamer_profile_attributes", "three_size"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("form.comment")}
              name={["streamer_profile_attributes", "comment"]}
              labelAlign="left"
              labelCol={{ span: 8 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={5}>
            <Text type="danger">{t("common.requiredNote")}</Text>
          </Col>
          <Col span={5}>
            <Form.Item>
              <Button
                disabled={
                  !(
                    form
                      .getFieldsError()
                      .every((fieldErr) => fieldErr.errors.length === 0) &&
                    !isFileTooLarge(currentLocalFile?.background) &&
                    !isFileTooLarge(currentLocalFile?.avatar)
                  )
                }
                block
                type="primary"
                htmlType="submit"
              >
                {t("common.confirm").toUpperCase()}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEditAccountModal;
