import { Table, Button, Tooltip, Empty } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined } from "@ant-design/icons";

import { ILanguage } from "constants/interface";
import { useState } from "react";
import { LanguageType, UpdateType } from "constants/enum";
import LanguageModal from "./LanguageModal";
import { getLang } from "utils/helper";
import styles from "./styles.module.scss";

interface IProps {
  languages: ILanguage[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
}

export default function LanguageTable({
  languages,
  loading,
  pageIndex,
  pageSize,
}: IProps) {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<ILanguage>();
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const columns: ColumnsType<ILanguage> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      render: (value, record, index) => (
        <span>{(pageIndex - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: t("table.languageKey"),
      dataIndex: "value",
    },
    {
      title: t("table.langJa"),
      dataIndex: "langJa",
      render: (value, record, index) =>
        getLang(LanguageType.JA, record.translations),
    },
    {
      title: t("table.langEn"),
      dataIndex: "langEn",
      render: (value, record, index) =>
        getLang(LanguageType.EN, record.translations),
    },
    {
      title: t("table.env"),
      dataIndex: "env",
      render: (value, record, index) => (
        <span className={styles.env}>{record.language_env}</span>
      ),
    },
    {
      title: t("table.action"),
      render: (text, record) => {
        return (
          <>
            <Tooltip placement="topLeft" title={t("tooltip.editLanguageKey")}>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setCurrentLanguage(record);
                  setIsVisibleModal(true);
                }}
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        locale={{ emptyText: () => <Empty description={t("common.noData")} /> }}
        rowKey="id"
        dataSource={languages || []}
        columns={columns}
        pagination={false}
        loading={loading}
        bordered
      />
      {isVisibleModal && (
        <LanguageModal
          isVisibleModal={isVisibleModal}
          setIsVisibleModal={setIsVisibleModal}
          type={UpdateType.UPDATE}
          data={currentLanguage}
        />
      )}
    </>
  );
}
