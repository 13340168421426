import LogoImg from "./logo-img.svg";
import UserIconDefault from "./user-icon-default.png";
import LanguageIcon from "./language-icon.png";
import leftArrowIcon from "./left-arrow.png";
import avatarWrapperIcon from "./avatar-wrapper-icon.svg";
import avatarWrapperIcon2 from "./avatar-wrapper-icon2.svg";

const ImagesObj = {
  LogoImg,
  UserIconDefault,
  LanguageIcon,
  leftArrowIcon,
  avatarWrapperIcon,
  avatarWrapperIcon2,
};

export default ImagesObj;
