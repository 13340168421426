import JSZip from "jszip";
import { saveAs } from "file-saver";

import { getLanguages } from "api/language";
import { LanguageType } from "constants/enum";
import { ITranslation } from "constants/interface";

export const getLang = (
  langType: LanguageType,
  translations?: ITranslation[]
) => {
  if (!translations) return "";
  const lang = translations.filter((t: ITranslation) => t.code === langType);
  return lang.length > 0 ? lang[0].value : "";
};

export const handleDownloadLanguage = async (env: any) => {
  if (!env) return;
  const getEnvJa = () =>
    getLanguages({
      environment: env,
      code: LanguageType.JA,
    });
  const getEnvEn = () =>
    getLanguages({
      environment: env,
      code: LanguageType.EN,
    });
  const [envJa, envEn] = await Promise.all([getEnvJa(), getEnvEn()]);
  const zip = new JSZip();
  const cmsJsonJa = JSON.stringify(envJa, null, 2);
  zip.file(`${env}_ja.json`, new Blob([cmsJsonJa]));
  const cmsJsonEn = JSON.stringify(envEn, null, 2);
  zip.file(`${env}_en.json`, new Blob([cmsJsonEn]));
  zip.generateAsync({ type: "blob" }).then((content: any) => {
    saveAs(content, `${env}.zip`);
  });
};
