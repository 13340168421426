import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import vi from "./locales/vi.json";
import en from "./locales/en.json";
import ja from "./locales/jp.json";
import { handleErrorMessage } from "i18n";
import { getLanguages } from "api/language";
import { EnvType, LanguageType } from "constants/enum";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ja",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ja: { translation: ja },
      // vi: { translation: vi },
      en: { translation: en },
    },
  });

export const setResourceI18n = async () => {
  try {
    const getEnvEn = () =>
      getLanguages({
        environment: EnvType.CMS,
        code: LanguageType.EN,
      });
    const getEnvJa = () =>
      getLanguages({
        environment: EnvType.CMS,
        code: LanguageType.JA,
      });
    const [dataEn, dataJa] = await Promise.all([getEnvEn(), getEnvJa()]);
    i18n.addResourceBundle("en", "translation", dataEn, true, true);
    i18n.addResourceBundle("ja", "translation", dataJa, true, true);
  } catch (error) {
    handleErrorMessage(error);
  }
};
