import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import classNames from "classnames";
import {
  DashboardOutlined,
  AudioOutlined,
  SettingOutlined,
  UserOutlined,
  TagsOutlined,
  ProfileOutlined,
  InfoCircleOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import styles from "./styles.module.scss";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import ImagesObj from "assets/images";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

export default function SideNav() {
  const { t } = useTranslation();
  const { collapsed } = useToggleSideNav();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("1");

  const routes = [
    {
      key: "1",
      text: t("tab.dashboard"),
      url: "/",
      icon: <DashboardOutlined />,
    },
    {
      key: "2",
      text: t("tab.accounts"),
      url: "/accounts",
      icon: <UserOutlined />,
    },
    {
      key: "3",
      text: t("tab.audios"),
      url: "/audios",
      icon: <AudioOutlined />,
    },
    {
      key: "4",
      text: t("tab.system"),
      url: "/system",
      icon: <DesktopOutlined />,
      children: [
        {
          key: "4.1",
          text: t("tab.language"),
          url: "/system/language",
          icon: (
            <img src={ImagesObj.LanguageIcon} className={styles.languageIcon} />
          ),
        },
      ],
    },
    {
      key: "5",
      text: t("tab.settings"),
      icon: <SettingOutlined />,
      children: [
        {
          key: "5.1",
          text: t("tab.hashtags"),
          icon: <TagsOutlined />,
          url: "/settings/hashtags",
        },
        {
          key: "5.2",
          text: t("tab.terms"),
          icon: <ProfileOutlined />,
          url: "/settings/terms",
        },
        {
          key: "5.3",
          text: t("tab.company"),
          icon: <InfoCircleOutlined />,
          url: "/settings/company",
        },
      ],
    },
  ];

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || "###")) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
  }, [location.pathname]);

  return (
    <div
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      style={{ width: collapsed ? 80 : 250, transition: "width 0.3s" }}
    >
      <Link className={styles.logo} to="/">
        <img
          src={ImagesObj.LogoImg}
          alt="LogoImg"
          style={collapsed ? { width: 50, height: "auto" } : {}}
        />
      </Link>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[]}
        mode="inline"
        inlineCollapsed={collapsed}
      >
        {routes.map((route) => {
          if (route.children) {
            return (
              <SubMenu key={route.key} icon={route.icon} title={route.text}>
                {route.children?.map((childRoute) => (
                  <Menu.Item key={childRoute.key} icon={childRoute?.icon}>
                    <Link to={childRoute.url}>{childRoute.text}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }
          return (
            <Menu.Item key={route.key} icon={route.icon}>
              <Link to={route.url}>{route.text}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
}
