import { Table, Switch, Tag, Empty } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";

import { IAudio, IHashtag } from "constants/interface";
import { StatusAudio } from "constants/enum";
import styles from "./styles.module.scss";

interface IProps {
  dashboard: IAudio[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
}

export default function DashboardTable({
  dashboard,
  loading,
  pageIndex,
  pageSize,
}: IProps) {
  const { t } = useTranslation();

  const columns: ColumnsType<IAudio> = [
    {
      title: t("table.rank"),
      dataIndex: "index",
      render: (value: any, record: IAudio, index: number) => (
        <div className={styles.itemTable}>
          {(pageIndex - 1) * pageSize + index + 1}
        </div>
      ),
      width: "5%",
    },
    {
      title: t("table.audio"),
      dataIndex: "audio",
      render: (value: any, record: IAudio, index: number) => (
        <div className={styles.listenIcon}>
          {record.background?.thumb?.url ? (
            <img src={record.background?.thumb?.url} alt="Background" />
          ) : (
            <div className={styles.imgUndefined} />
          )}
        </div>
      ),
      width: "12%",
    },
    {
      title: t("table.audienceCount"),
      dataIndex: "audience_count",
      render: (value: any, record: IAudio, index: number) => (
        <div className={styles.itemTable}>{record.audience_count}</div>
      ),
      width: "10%",
    },
    {
      title: t("table.hashtags"),
      dataIndex: "hashtags",
      render: (value: any, record: IAudio, index: number) => (
        <>
          {record.hashtags.map((hashtag: IHashtag, hashtagIndex: number) => (
            <Tag style={{ fontSize: 14 }}>{`#${hashtag.name}`}</Tag>
          ))}
        </>
      ),
    },
    {
      title: t("table.owner"),
      dataIndex: "streamer",
    },
    {
      title: t("table.createdAt"),
      dataIndex: "createdAt",
      render: (value: any, record: IAudio, index: number) => (
        <span>{record.stream_date}</span>
      ),
    },
    {
      title: t("table.status"),
      render: (text: any, record: IAudio) => {
        return (
          <>
            <Switch
              checked={record.status === StatusAudio.ENABLE}
              disabled={true}
            />
          </>
        );
      },
      width: "8%",
    },
  ];

  return (
    <>
      <Table
        locale={{ emptyText: () => <Empty description={t("common.noData")} /> }}
        rowKey="id"
        dataSource={dashboard || []}
        columns={columns}
        pagination={false}
        loading={loading}
        bordered
      />
    </>
  );
}
