import { Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAudioItem } from "../AudioTable";

interface IProps {
  itemFromProps: IAudioItem;
  isModalVisible: boolean;
  onCancel: () => void;
  onOk: (inputs: Array<string>) => void;
}

export default function AudioEditHashtagsModal({
  onCancel,
  onOk,
  isModalVisible,
  itemFromProps,
}: IProps) {
  const { t } = useTranslation();
  const [initialInputs, setInitialInputs] = useState<Array<string>>(
    itemFromProps?.hashtags?.map((hashtagItem) => {
      return hashtagItem?.name;
    })
  );
  const [inputs, setInputs] = useState(
    itemFromProps?.hashtags?.map((hashtagItem) => {
      return hashtagItem?.name;
    })
  );

  useEffect(() => {
    setInitialInputs(
      itemFromProps?.hashtags?.map((hashtagItem) => {
        return hashtagItem?.name;
      })
    );
    setInputs(
      itemFromProps?.hashtags?.map((hashtagItem) => {
        return hashtagItem?.name;
      })
    );
  }, [itemFromProps?.hashtags]);

  return (
    <Modal
      title={t("modal.editHashtagTitle")}
      onCancel={onCancel}
      visible={isModalVisible}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      onOk={() => {
        onOk(inputs);
      }}
      maskClosable={false}
      destroyOnClose={true}
      width={"30%"}
    >
      {inputs?.map((inputItem, inputIndex) => {
        return (
          <Row key={`${inputIndex}`} style={{ marginBottom: 20 }}>
            <Input
              value={inputItem}
              allowClear
              onChange={(event) => {
                const newInputs = inputs?.map((newIItem, newIIndex) => {
                  if (newIIndex === inputIndex) {
                    return event.target.value;
                  }
                  return newIItem;
                });
                setInputs(newInputs);
              }}
              maxLength={50}
            />
          </Row>
        );
      })}
    </Modal>
  );
}
