import moment, { Moment } from "moment";

export const convertToFullDate = (date?: string | Moment) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};

export const convertToFullDateJapanese = (date?: string) => {
  if (!date) return "";
  return moment(date, "YYYY-MM-DD").format("YYYY年MM月DD日");
};
