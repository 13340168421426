import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

interface IProps {
  isVisibleModal: boolean;
  content: string;
  onOk: () => void;
  onCancel: () => void;
  loading?: boolean;
}

export default function ModalConfirm({
  content,
  onOk,
  onCancel,
  loading,
  isVisibleModal,
}: IProps) {
  const [t] = useTranslation();

  return (
    <Modal
      title={null}
      footer={null}
      onOk={onOk}
      onCancel={onCancel}
      visible={isVisibleModal}
      closable={false}
      className={styles.modalContainer}
      maskClosable={false}
    >
      <div className="white-pre-live">{content}</div>
      <div className={styles.footerModal}>
        <Button onClick={onCancel}>{t("common.cancel")}</Button>
        <Button
          onClick={onOk}
          className="ml-10"
          disabled={loading}
          type="primary"
        >
          {t("common.ok")}
        </Button>
      </div>
    </Modal>
  );
}
