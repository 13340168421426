import { Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import type { PaginationProps } from "antd";

import styles from "./styles.module.scss";
import AudioTable from "./AudioTable";
import useFilter from "utils/hooks/useFilter";
import { IFilter } from "constants/interface";
import { useQuery } from "react-query";
import AudioFilter from "./AudioFilter";
import { getListStreamingHistory } from "api/audio";

const defaultFilter: IFilter = {
  page_index: 1,
  page_size: 10,
};

export default function Audios() {
  const { t } = useTranslation();
  const { filter, handleFilterChange, handlePageChange, handleSearch } =
    useFilter(defaultFilter);
  const {
    data: listAudioHistory,
    isFetching: loadingListAudioHistory,
    refetch,
  } = useQuery(["listAudioHistory", filter], () =>
    getListStreamingHistory(filter)
  );
  const handleReload = () => {
    refetch();
  };
  const showTotal: PaginationProps["showTotal"] = (total) =>
    t("pagination.total", { total });

  return (
    <div className={styles.container}>
      <h2>{t("audios.title")}</h2>
      <div className={styles.filterContainer}>
        <AudioFilter
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
        />
        <Button icon={<ReloadOutlined />} onClick={handleReload}>
          {t("common.reload")}
        </Button>
      </div>
      <AudioTable
        loading={loadingListAudioHistory}
        audios={listAudioHistory?.data || []}
        pageIndex={filter.page_index || 1}
        pageSize={filter.page_size || 10}
      />
      <p />
      <Pagination
        current={filter.page_index}
        total={listAudioHistory?.pagination?.count || 0}
        pageSize={filter.page_size}
        onChange={handlePageChange}
        showSizeChanger={false}
        showTotal={showTotal}
      />
    </div>
  );
}
