import { Modal, Form, Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, KeyboardEvent } from "react";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

import { KeyBoard, UpdateType } from "constants/enum";
import { IHashtag } from "constants/interface";
import styles from "./styles.module.scss";
import { updateHashtag } from "api/hashtags";

interface IProps {
  isVisibleModal: boolean;
  setIsVisibleModal: Function;
  type: UpdateType;
  data?: IHashtag;
}

export default function HashtagModal({
  isVisibleModal,
  setIsVisibleModal,
  type,
  data,
}: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { mutate: putHashtag, isLoading: loadingPutHashtag } = useMutation(
    (payload: any) => updateHashtag(payload, data?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listHashtag");
        setIsVisibleModal(false);
        message.success(t("form.updateHashtagSuccess"));
      },
    }
  );

  useEffect(() => {
    if (type === UpdateType.UPDATE) {
      form.setFieldsValue({ name: data?.name });
    }
  }, []);

  const handleSubmit = (payload: any) => {
    putHashtag({ name: payload?.name });
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const key = e.which || e.keyCode;
    if (key === KeyBoard.SPACE) {
      e.preventDefault();
      return;
    }
  };

  return (
    <Modal
      title={
        type === UpdateType.ADD
          ? t("modal.addHashtagTitle")
          : t("modal.editHashtagTitle")
      }
      visible={isVisibleModal}
      onCancel={() => setIsVisibleModal(false)}
      footer={null}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label={t("form.hashtagLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input maxLength={50} onKeyDown={handleKeyDown} />
        </Form.Item>
        <div className={styles.footerModal}>
          <Button onClick={() => setIsVisibleModal(false)}>
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => {
              form.submit();
            }}
            className="ml-10"
            disabled={loadingPutHashtag}
            type="primary"
          >
            {t("common.ok")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
