/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import { editChangePasswordAccountAPI } from "api/accounts";
import LoadingApi from "components/LoadingApi";
import { IAccountParamsEditCreate } from "constants/interface";
import { handleErrorMessage, handleSuccessMessage } from "i18n";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RuleForm } from "utils/helper/ruleForms";

interface Props {
  formFromProps?: IAccountParamsEditCreate;
  isModalVisible: boolean;
  handleCancel(): void;
  handleOk(): void;
}

const { Text } = Typography;

const ChangePasswordAccountModal = (props: Props) => {
  const { isModalVisible, handleCancel, handleOk, formFromProps } = props;
  const { t } = useTranslation();
  const form = Form.useForm<any>()[0];
  const [isLoadingApi, setIsLoadingApi] = useState(false);

  /**
   * Process files and data
   * */
  const handleSubmit = async (payload: any) => {
    try {
      setIsLoadingApi(true);
      const params = _.pick(payload, ["new_password", "password_confirmation"]);
      await editChangePasswordAccountAPI({ ...params, id: formFromProps?.id });
      handleSuccessMessage(t("form.changePasswordSuccess"));
      form.resetFields();
      handleOk();
    } finally {
      setIsLoadingApi(false);
    }
  };

  return (
    <Modal
      title={t("modal.editPasswordTitle")}
      visible={isModalVisible}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
      width={"45%"}
    >
      <LoadingApi isLoading={isLoadingApi} />
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          validateFirst
          label={t("form.newPassword")}
          name="new_password"
          rules={RuleForm.newPassword()}
          labelAlign="left"
          labelCol={{ span: 8 }}
          normalize={(value) => value.trim()}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          validateFirst
          label={t("form.confirmPassword")}
          name="password_confirmation"
          rules={RuleForm.confirmPassword()}
          labelAlign="left"
          labelCol={{ span: 8 }}
          normalize={(value) => value.trim()}
        >
          <Input.Password />
        </Form.Item>
        <Row justify="space-between">
          <Col span={5}>
            <Text type="danger">{t("common.requiredNote")}</Text>
          </Col>
          <Col span={5}>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                {t("common.confirm").toUpperCase()}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePasswordAccountModal;
