export enum Direction {
  Up = 1,
  Down,
  Left,
  Right,
}

export enum StatusType {
  All = "all",
  Active = "active",
  Streaming = "streaming",
  Inactive = "inactive",
}

export enum UpdateType {
  ADD = "add",
  UPDATE = "update",
}

export enum UploaderType {
  TAKE_PHOTO = "takePhoto",
  UPLOAD_PHOTO = "uploadPhoto",
}

export enum KeyBoard {
  SPACE = 32,
}

export enum DebounceTime {
  DEFAULT = 500,
}

export enum StatusAudio {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum DateRangePickerType {
  WEEK = "week",
  MONTH = "month",
}

export enum EnvType {
  CMS = "CMS",
  WEB = "WEB",
  SERVER = "SERVER",
}

export enum LanguageType {
  JA = "ja",
  EN = "en",
  KEY = "cimode",
}

export enum QueryKey {
  LIST_CONVERSATION = "listConversation",
  LIST_MESSAGE = "listMessage",
}

export enum SenderType {
  IDOL = "idol",
  GUEST = "guest",
}

export enum FileType {
  IMAGE = "image",
  VIDEO = "video",
}
