import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { Button, message } from "antd";
import { createCompanyAPI, getCompanyAPI, updateCompanyAPI } from "api/company";
import { handleErrorMessage } from "i18n";
import { isNumber } from "lodash";
import configs from "constants/config";

export default function Company() {
  const { t } = useTranslation();
  const [id, setId] = useState<number | undefined>(undefined);
  const [info, setInfo] = useState<string | undefined>("");

  const handleGetCompany = async () => {
    try {
      const companyResult = await getCompanyAPI();
      setId(companyResult?.id);
      setInfo(companyResult?.info);
    } catch (err) {}
  };

  const handleSave = async () => {
    try {
      if (!isNumber(id)) {
        await createCompanyAPI({ info });
        message.success(t("company.createSuccess"));
        handleGetCompany();
        return;
      }
      await updateCompanyAPI({ info }, id);
      message.success(t("company.updateSuccess"));
      handleGetCompany();
    } catch (err) {}
  };

  useEffect(() => {
    handleGetCompany();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <h2>{t("company.title")}</h2>
        <ReactQuill
          theme="snow"
          value={info}
          onChange={(value: any) => {
            setInfo(value);
          }}
          style={{ height: "100%" }}
          modules={configs.QUILL_MODULES}
          formats={configs.QUILL_FORMATS}
        />
      </div>
      <div className={styles.btnSaveWrapper}>
        <Button type="primary" onClick={handleSave}>
          {t("common.save")}
        </Button>
      </div>
    </>
  );
}
