import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { IFilter } from "constants/interface";
import { DebounceTime } from "constants/enum";
import { defaultFilterEx } from "constants/defaultValue";

export default function useFilter(defaultFilter?: IFilter) {
  const [filter, setFilter] = useState<IFilter>(
    defaultFilter || defaultFilterEx
  );

  const handleFilterChange = (changeValue: IFilter) => {
    setFilter({
      ...filter,
      ...changeValue,
      page_index: 1,
      page: 1,
      isFilterChange: true,
    });
  };
  const resetFilter = () => {
    setFilter(defaultFilter || defaultFilterEx);
  };
  const handlePageChange = (pageIndex: number, pageSize: number) => {
    setFilter({ ...filter, page_index: pageIndex, page_size: pageSize });
  };
  const debounceKeyword = useDebouncedCallback((keyword) => {
    handleFilterChange({ keyword: keyword?.trim() });
  }, DebounceTime.DEFAULT);
  const debounceName = useDebouncedCallback((name) => {
    handleFilterChange({ name: name?.trim() });
  }, DebounceTime.DEFAULT);

  const keywordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scrollable = document.querySelector(
      "#conversationScrollContainer"
    ) as HTMLDivElement;
    scrollable?.scrollTo({ top: 0 });
    debounceKeyword(e.target.value);
  };
  const envSearch = (value: any) => {
    const env =
      Array.isArray(value) && value?.length > 0
        ? JSON.stringify(value)
        : undefined;
    handleFilterChange({ environment: env });
  };
  const nameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scrollable = document.querySelector(
      ".conversationScrollContainer"
    ) as HTMLDivElement;
    scrollable?.scrollTo({ top: 0 });
    debounceName(e.target.value);
  };

  return {
    filter,
    handleFilterChange,
    resetFilter,
    handlePageChange,
    setFilter,
    handleSearch: {
      keywordSearch,
      envSearch,
      nameSearch,
    },
  };
}
