import { Modal, Form, Input, Button, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { EnvType, LanguageType, UpdateType } from "constants/enum";
import { ILanguage } from "constants/interface";
import styles from "./styles.module.scss";
import { getLang } from "utils/helper";
import { updateLanguageKey } from "api/language";

const { Option } = Select;

interface IProps {
  isVisibleModal: boolean;
  setIsVisibleModal: Function;
  type: UpdateType;
  data?: ILanguage;
}

export default function LanguageModal({
  isVisibleModal,
  setIsVisibleModal,
  type,
  data,
}: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: putLanguageKey, isLoading: loadingLanguageKey } = useMutation(
    (params: any) => updateLanguageKey(params?.payload, params?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("languageKeys");
        setIsVisibleModal(false);
        message.success(t("form.updateLanguageKeySuccess"));
      },
    }
  );

  useEffect(() => {
    if (type === UpdateType.UPDATE) {
      form.setFieldsValue({
        key: data?.value,
        env: data?.language_env,
        langJa: getLang(LanguageType.JA, data?.translations),
        langEn: getLang(LanguageType.EN, data?.translations),
      });
    }
  }, []);

  const handleSubmit = (payload: any) => {
    if (type === UpdateType.UPDATE) {
      putLanguageKey({
        id: data?.id,
        payload: {
          environment: payload?.env,
          translations: [
            {
              code: LanguageType.JA,
              value: payload?.langJa,
            },
            {
              code: LanguageType.EN,
              value: payload?.langEn,
            },
          ],
        },
      });
    }
  };

  return (
    <Modal
      title={t("modal.editLanguageTitle")}
      visible={isVisibleModal}
      onCancel={() => setIsVisibleModal(false)}
      footer={null}
      className={styles.languageModal}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="key"
          label={t("form.keyLanguageLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input maxLength={255} disabled={type === UpdateType.UPDATE} />
        </Form.Item>
        <Form.Item
          name="env"
          label={t("form.envLanguageLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select disabled={type === UpdateType.UPDATE}>
            <Option value={EnvType.WEB}>{EnvType.WEB}</Option>
            <Option value={EnvType.CMS}>{EnvType.CMS}</Option>
            <Option value={EnvType.SERVER}>{EnvType.SERVER}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="langJa"
          label={t("form.valueJaLanguageLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item
          name="langEn"
          label={t("form.valueEnLanguageLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <div className={styles.footerModal}>
          <Button onClick={() => setIsVisibleModal(false)}>
            {t("form.cancelBtn")}
          </Button>
          <Button
            onClick={() => {
              form.submit();
            }}
            className="ml-10"
            disabled={loadingLanguageKey}
            type="primary"
          >
            {t("form.editBtn")}
          </Button>
        </div>
        <div className="textRequired">{t("form.textRequired")}</div>
      </Form>
    </Modal>
  );
}
