import i18next from "i18next";
import { REGEX_EMAIL, REGEX_EMOJI, REGEX_JAPANESE } from "./regex";

const { t } = i18next;
export const RuleForm = {
  email: () => [
    {
      required: true,
      message: t("validate.emailRequired"),
    },
    () => ({
      validator(_: any, value: any) {
        if (
          value?.toString().match(REGEX_EMOJI) ||
          !value?.toString().match(REGEX_EMAIL)
        ) {
          return Promise.reject(new Error(t("validate.emailWrongFormat")));
        }
        return Promise.resolve();
      },
    }),
  ],
  password: () => [
    {
      required: true,
      message: t("validate.passwordRequired"),
    },
    () => ({
      validator(_: any, value: any) {
        if (
          value?.toString().match(REGEX_EMOJI) ||
          value?.toString().match(REGEX_JAPANESE)
        ) {
          return Promise.reject(new Error(t("validate.passwordWrongFormat")));
        }
        if (value?.toString()?.length < 6) {
          return Promise.reject(new Error(t("validate.passwordTooShort")));
        }
        return Promise.resolve();
      },
    }),
  ],
  newPassword: () => [
    { required: true, message: t("validate.passwordRequired") },
    () => ({
      validator(_: any, value: any) {
        if (
          value?.toString().match(REGEX_EMOJI) ||
          value?.toString().match(REGEX_JAPANESE)
        ) {
          return Promise.reject(new Error(t("validate.passwordWrongFormat")));
        }
        if (value?.toString()?.length < 6) {
          return Promise.reject(new Error(t("validate.passwordTooShort")));
        }
        return Promise.resolve();
      },
    }),
  ],
  confirmPassword: () => [
    { required: true, message: t("validate.passwordRequired") },
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        if (!value || getFieldValue("new_password") === value) {
          return Promise.resolve();
        }
        if (
          value?.toString().match(REGEX_EMOJI) ||
          value?.toString().match(REGEX_JAPANESE)
        ) {
          return Promise.reject(new Error(t("validate.passwordWrongFormat")));
        }
        if (value?.toString()?.length < 6) {
          return Promise.reject(new Error(t("validate.passwordTooShort")));
        }
        return Promise.reject(new Error(t("validate.passwordNotMatch")));
      },
    }),
  ],
  name: () => [
    {
      required: true,
      message: t("validate.usernameRequired"),
    },
    () => ({
      validator(_: any, value: any) {
        if (value?.toString().match(REGEX_EMOJI)) {
          return Promise.reject(new Error(t("validate.usernameWrongFormat")));
        }
        return Promise.resolve();
      },
    }),
  ],
  audienceLimit: () => [
    {
      required: true,
    },
  ],
};
