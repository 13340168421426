import { Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import type { PaginationProps } from "antd";

import styles from "./styles.module.scss";
import HashtagTable from "./HashtagTable";
import useFilter from "utils/hooks/useFilter";
import { IFilter } from "constants/interface";
import { useQuery } from "react-query";
import { getListHashtag } from "api/hashtags";
import HashtagFilter from "./HashtagFilter";

const defaultFilter: IFilter = {
  page_index: 1,
  page_size: 10,
};

export default function Hashtags() {
  const { t } = useTranslation();
  const { filter, handleFilterChange, handlePageChange, handleSearch } =
    useFilter(defaultFilter);
  const {
    data: listHashtag,
    isFetching: loadingListHashtag,
    refetch,
  } = useQuery(["listHashtag", filter], () => getListHashtag(filter));
  const handleReload = () => {
    refetch();
  };
  const showTotal: PaginationProps["showTotal"] = (total) =>
    t("pagination.total", { total });

  return (
    <div className={styles.container}>
      <h2>{t("hashtags.title")}</h2>
      <div className={styles.filterContainer}>
        <HashtagFilter
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
        />
        <Button icon={<ReloadOutlined />} onClick={handleReload}>
          {t("common.reload")}
        </Button>
      </div>
      <HashtagTable
        loading={loadingListHashtag}
        hashtags={listHashtag?.data || []}
        pageIndex={filter.page_index || 1}
        pageSize={filter.page_size || 10}
      />
      <p />
      <Pagination
        current={filter.page_index}
        total={listHashtag?.pagination?.count || 0}
        pageSize={filter.page_size}
        onChange={handlePageChange}
        showSizeChanger={false}
        showTotal={showTotal}
      />
    </div>
  );
}
