import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { Button, message } from "antd";
import { createTermsAPI, getTermsAPI, updateTermsAPI } from "api/terms";
import { handleErrorMessage } from "i18n";
import { isNumber } from "lodash";
import configs from "constants/config";

export default function Terms() {
  const { t } = useTranslation();
  const [id, setId] = useState<number | undefined>(undefined);
  const [info, setInfo] = useState<string | undefined>("");

  const handleGetTerms = async () => {
    try {
      const termsResult = await getTermsAPI();
      setId(termsResult?.id);
      setInfo(termsResult?.info);
    } catch (err) {}
  };

  const handleSave = async () => {
    try {
      if (!isNumber(id)) {
        await createTermsAPI({ info });
        message.success(t("terms.updateSuccess"));
        handleGetTerms();
        return;
      }
      await updateTermsAPI({ info: info }, id);
      handleGetTerms();
      message.success(t("terms.updateSuccess"));
    } catch (err) {}
  };

  useEffect(() => {
    handleGetTerms();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <h2>{t("terms.title")}</h2>
        <ReactQuill
          theme="snow"
          value={info}
          onChange={(value) => {
            setInfo(value);
          }}
          style={{ height: "100%" }}
          modules={configs.QUILL_MODULES}
          formats={configs.QUILL_FORMATS}
        />
      </div>
      <div className={styles.btnSaveWrapper}>
        <Button type="primary" onClick={handleSave}>
          {t("common.save")}
        </Button>
      </div>
    </>
  );
}
