import { useState } from "react";
import { Pagination, Button, Row, Input, Select } from "antd";
import { useQuery } from "react-query";
import AccountList from "./AccountList";
import styles from "./styles.module.scss";
import AddEditAccountModal from "./components/AddEditAccountModal";
import { useTranslation } from "react-i18next";
import { getListAccountsAPI } from "api/accounts";
import { PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import FakeData from "constants/data";
import i18next from "i18next";
import { history } from "App";
import { useSearchParams } from "react-router-dom";
import { StatusType } from "constants/enum";

const { Search } = Input;
const { Option } = Select;

const page_size = 10;
interface IFilter {
  page_index: number;
  page_size: number;
  keyword?: string;
  status?: string | null;
}

export default function Accounts() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const statusArrDisplayOnlyKey = FakeData.statusArr.map(
    (item) => item.valueDisplayKey
  );
  const [filter, setFilter] = useState<IFilter>({
    page_index: +(searchParams.get("pageIndex") || 1),
    page_size: +(searchParams.get("pageSize") || 10),
    keyword: searchParams.get("keyword") || "",
    status: searchParams.get("status") ? searchParams.get("status") : undefined,
  });
  const getLocalStatusKey = (status: any) => {
    if (!status) return null;
    const statusArrItem: any = FakeData.statusArr.find(
      (item) => item?.valueApi === status
    );
    return statusArrItem?.valueDisplayKey;
  };
  const [statusValue, setStatusValue] = useState<any>(
    getLocalStatusKey(filter?.status)
      ? t(getLocalStatusKey(filter?.status))
      : t(statusArrDisplayOnlyKey[0])
  );
  const [statusKey, setStatusKey] = useState<any>(statusArrDisplayOnlyKey[0]);
  const [isAddAccountModalOpened, setIsAddAccountModalOpened] = useState(false);
  const { data, isFetching, refetch } = useQuery(
    ["accounts", filter],
    () => getListAccountsAPI(filter),
    {
      keepPreviousData: true,
    }
  );
  const handleOpenAddAccountModal = () => {
    setIsAddAccountModalOpened(true);
  };
  const handlePageChange = (page: number) => {
    pushFilterToUrl({ ...filter, page_index: page });
    setFilter((oldFilter) => ({ ...oldFilter, page_index: page }));
  };
  const refreshAfterDataChanged = () => {
    if (filter?.page_index === 1) {
      refetch();
      return;
    }
    handlePageChange(1);
  };
  const onSearch = (value: string) => {
    pushFilterToUrl({ ...filter, page_index: 1, keyword: value.trim() });
    setFilter({ ...filter, page_index: 1, keyword: value.trim() });
  };
  const onStatusSelect = (chosenValueDisplayTranslated: any) => {
    const statusArrItem: any = FakeData.statusArr.find(
      (item) => t(item.valueDisplayKey) === chosenValueDisplayTranslated
    );
    const { valueApi, valueDisplayKey } = statusArrItem;
    setStatusValue(chosenValueDisplayTranslated);
    setStatusKey(valueDisplayKey);
    pushFilterToUrl({ ...filter, page_index: 1, status: valueApi });
    setFilter({ ...filter, page_index: 1, status: valueApi });
  };

  i18next.on("languageChanged", () => {
    setStatusValue(t(statusKey));
  });

  const pushFilterToUrl = (filter: IFilter) => {
    history.push({
      pathname: "/accounts",
      search: `?pageIndex=${filter?.page_index}&&pageSize=${
        filter?.page_size
      }&&keyword=${filter?.keyword}&&status=${filter?.status ?? ""}`,
    });
  };

  return (
    <div className={styles.container}>
      <h2>{t("accounts.title")}</h2>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Row justify="space-between">
          <Search
            placeholder={t("accounts.placeHolder")}
            onSearch={onSearch}
            style={{ width: 300 }}
            defaultValue={filter?.keyword}
          />
          <Select
            showSearch={false}
            style={{ width: 150, marginLeft: 20 }}
            onSelect={onStatusSelect}
            value={statusValue}
          >
            {statusArrDisplayOnlyKey.map((sttItem, sttIndex) => {
              return (
                <Option key={String(sttIndex)} value={t(String(sttItem))}>
                  {t(String(sttItem))}
                </Option>
              );
            })}
          </Select>
        </Row>
        <Row justify="space-between">
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={handleOpenAddAccountModal}
            style={{ marginRight: 10 }}
          >
            {t("common.add")}
          </Button>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => {
              history.push("/accounts");
              window.location.reload();
            }}
          >
            {t("common.reload")}
          </Button>
        </Row>
      </Row>
      <AccountList
        loading={isFetching}
        accounts={data?.data}
        handleAfterEdit={() => {
          refreshAfterDataChanged();
        }}
        currentPageIndex={filter.page_index}
        currentPageSize={filter.page_size}
        filter={filter}
      />
      <p />
      <Pagination
        current={data?.pagination?.page}
        total={data?.pagination?.count}
        pageSize={page_size}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
      <AddEditAccountModal
        isModalVisible={isAddAccountModalOpened}
        handleCancel={() => {
          setIsAddAccountModalOpened(false);
        }}
        handleOk={() => {
          setIsAddAccountModalOpened(false);
          refreshAfterDataChanged();
        }}
      />
    </div>
  );
}
