import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";

import styles from "./styles.module.scss";
import classNames from "classnames";
import LanguageUploadModal from "./LanguageUploadModal";
import { ILanguageEnv } from "constants/interface";
import { handleDownloadLanguage } from "utils/helper";

const { Option } = Select;

interface IProps {
  languageEnvs: ILanguageEnv[];
}

export default function LanguageUpload({ languageEnvs }: IProps) {
  const [t] = useTranslation();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const handleUpload = () => {
    setIsVisibleModal(true);
  };

  return (
    <div className={styles.uploadContainer}>
      <Select
        className={classNames(styles.select, "mr-10")}
        placeholder={
          <div className={styles.placeholder}>
            <DownloadOutlined />
            <span className="ml-5">{t("placeholder.downloadLanguage")}</span>
          </div>
        }
        onChange={(value: any) => handleDownloadLanguage(value)}
        allowClear
      >
        {languageEnvs?.map((le: ILanguageEnv) => (
          <Option value={le.name} key={le.id}>
            {le.name}
          </Option>
        ))}
      </Select>
      <Button type="primary" onClick={handleUpload}>
        {t("language.uploadBtn")}
      </Button>
      {isVisibleModal && (
        <LanguageUploadModal
          isVisibleModal={isVisibleModal}
          setIsVisibleModal={setIsVisibleModal}
        />
      )}
    </div>
  );
}
