import { Input } from "antd";
import { useTranslation } from "react-i18next";

import { IFilter } from "constants/interface";

interface IProps {
  filter: IFilter;
  handleFilterChange: (changeValue: IFilter) => void;
  handleSearch: any;
}

export default function AudioFilter({ filter, handleSearch }: IProps) {
  const [t] = useTranslation();

  return (
    <div>
      <Input.Search
        placeholder={t("placeholder.keywordHashtagAndStreamer")}
        onChange={handleSearch?.keywordSearch}
      />
    </div>
  );
}
