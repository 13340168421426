import { Modal } from "antd";

import styles from "./styles.module.scss";

interface IProps {
  isVisibleModal: boolean;
  children: JSX.Element;
  onCancel: () => void;
}

export default function AudioModal({
  onCancel,
  isVisibleModal,
  children,
}: IProps) {
  return (
    <Modal
      title={null}
      footer={null}
      onCancel={onCancel}
      visible={isVisibleModal}
      closable={false}
      className={styles.audioModal}
    >
      {children}
    </Modal>
  );
}
