import { useEffect, useMemo, useState } from "react";
import { Table, Button, Tag, Tooltip, Switch, Modal, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { StatusType } from "constants/enum";
import styles from "./styles.module.scss";
import {
  SyncOutlined,
  EditOutlined,
  IdcardOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { isEqual } from "lodash";
import { handleSuccessMessage } from "i18n";
import AddEditAccountModal from "./components/AddEditAccountModal";
import { editStatusAccountAPI } from "api/accounts";
import ChangePasswordAccountModal from "./components/ChangePasswordAccountModal";
import ImagesObj from "assets/images";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { IFilter } from "constants/interface";

interface IAccountItem {
  id: number;
  last_sign_in_at?: string;
  profile: {
    audience_limit: number;
    name: string;
    avatar?: {
      url: string;
      thumb: {
        url: string;
      };
    };
    background?: {
      url: string;
      thumb: {
        url: string;
      };
    };
    instagram_account?: string;
    youtube_account?: string;
    twitter_account?: string;
    sns_account?: string;
  };
  status: StatusType;
  username: string;
  indexInArr?: number;
  valueToChange?: boolean;
}

enum ModalType {
  DISABLE = 1,
  ENABLE = 2,
}

interface IProps {
  accounts: Array<IAccountItem>;
  loading: boolean;
  currentPageIndex: number;
  currentPageSize: number;
  handleAfterEdit(): void;
  filter?: IFilter;
}

const AccountList = ({
  accounts,
  loading,
  currentPageIndex,
  currentPageSize,
  handleAfterEdit,
  filter,
}: IProps) => {
  const { t } = useTranslation();
  const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);
  const [isModalEnableOpen, setIsModalEnableOpen] = useState(false);
  const [currentItemSelectedToToggle, setCurrentItemSelectedToToggle] =
    useState<IAccountItem | undefined>(undefined);
  const [currentItemEditing, setCurrentItemEditing] = useState<
    IAccountItem | undefined
  >(undefined);
  const [currentItemChangePassword, setCurrentItemChangePassword] = useState<
    IAccountItem | undefined
  >(undefined);
  const [statusEnableObj, setStatusEnableObj] = useState<
    Array<string> | undefined
  >();
  const [currentKey, setCurrentKey] = useState(
    localStorage.getItem("i18nextLng")
  );
  const [isVisibleListConversationModal, setIsVisibleListConversationModal] =
    useState(false);
  const navigate = useNavigate();

  i18next.on("languageChanged", (lang: string) => {
    setCurrentKey(lang);
  });

  const columns = useMemo(() => {
    return [
      {
        title: t("table.index"),
        dataIndex: "index",
        render: (text: any, record: any, index: number) => {
          return (
            <div className={styles.itemTable}>
              {(currentPageIndex - 1) * currentPageSize + index + 1}
            </div>
          );
        },
        width: "5%",
        fixed: "left",
      },
      {
        title: t("table.avatar"),
        dataIndex: "avatar",
        render: (text: any, record: any) => {
          return record?.profile?.avatar?.thumb?.url ? (
            <img
              className={styles.imgItemTable}
              src={record?.profile?.avatar?.thumb?.url}
              alt="table_avatar"
            />
          ) : (
            <div className={styles.imgItemTable}>
              <img src={ImagesObj.UserIconDefault} alt="User icon default" />
            </div>
          );
        },
        width: "5%",
      },
      {
        title: t("table.email"),
        dataIndex: "username",
        width: "15%",
      },
      {
        title: t("table.name"),
        dataIndex: ["profile", "name"],
        width: "15%",
      },
      {
        title: t("table.isStreaming"),
        dataIndex: "status",
        render: (text: any) => {
          return (
            text === StatusType.Streaming && (
              <Tag icon={<SyncOutlined spin />} color="success">
                {t("accounts.statusStreaming")}
              </Tag>
            )
          );
        },
        width: "8%",
      },
      {
        title: t("table.listenersLimit"),
        dataIndex: ["profile", "audience_limit"],
        render: (text: any) => {
          return <div className={styles.itemTable}>{text}</div>;
        },
        width: "5%",
      },
      {
        title: t("table.age"),
        dataIndex: ["profile", "age"],
        width: "5%",
      },
      {
        title: t("table.work"),
        dataIndex: ["profile", "work"],
        width: "5%",
      },
      {
        title: t("table.address"),
        dataIndex: ["profile", "address"],
        width: "5%",
      },
      {
        title: t("table.status"),
        dataIndex: "status",
        render: (text: any, record: any, index: number) => {
          return (
            <Switch
              checked={statusEnableObj?.[index] !== StatusType.Inactive}
              onChange={(checked) => {
                setCurrentItemSelectedToToggle({
                  ...accounts[index],
                  indexInArr: index,
                  valueToChange: checked,
                });
                if (!checked) setIsModalDisableOpen(true);
                else if (checked) setIsModalEnableOpen(true);
              }}
            />
          );
        },
        width: "8%",
      },
      {
        title: t("table.action"),
        render: (text: any, record: any) => {
          return (
            <>
              <Tooltip
                placement="topLeft"
                title={t("table.toolTipEditProfile")}
              >
                <Button
                  onClick={() => {
                    setCurrentItemEditing(record);
                  }}
                  icon={<EditOutlined />}
                ></Button>
              </Tooltip>
              &nbsp;
              <Tooltip
                placement="topLeft"
                title={t("table.toolTipChangePassword")}
              >
                <Button
                  icon={<IdcardOutlined />}
                  onClick={() => {
                    setCurrentItemChangePassword(record);
                  }}
                ></Button>
              </Tooltip>
              &nbsp;
              <Tooltip placement="topLeft" title={t("tooltip.viewChat")}>
                <Button
                  icon={<WechatOutlined />}
                  onClick={() => {
                    navigate(
                      `/accounts/${record?.uid}/conversations/${record?.last_conversation_id}`,
                      {
                        state: {
                          filter: filter,
                        },
                      }
                    );
                  }}
                  disabled={!record?.last_conversation_id}
                ></Button>
              </Tooltip>
            </>
          );
        },
        width: "12%",
        fixed: "right",
      },
    ];
  }, [statusEnableObj, currentPageIndex, currentPageSize, currentKey]);

  const handleCancelStatus = (modalType: ModalType) => {
    if (modalType === ModalType.DISABLE) {
      setIsModalDisableOpen(false);
      return;
    }
    setIsModalEnableOpen(false);
  };

  const handleOkStatus = async (modalType: ModalType) => {
    try {
      const newStatusEnableObj = statusEnableObj?.map(
        (sttEOItem: string, sttEOIndex: number) => {
          if (sttEOIndex === currentItemSelectedToToggle?.indexInArr) {
            return currentItemSelectedToToggle?.valueToChange
              ? StatusType.Active
              : StatusType.Inactive;
          }
          return sttEOItem;
        }
      );
      setStatusEnableObj(newStatusEnableObj);
      if (
        currentItemSelectedToToggle?.indexInArr !== null &&
        currentItemSelectedToToggle?.indexInArr !== undefined
      ) {
        await editStatusAccountAPI({
          id: currentItemSelectedToToggle?.id,
          status: (newStatusEnableObj as any)[
            currentItemSelectedToToggle?.indexInArr
          ],
        });
      }
      handleSuccessMessage(t("form.editAccountSuccess"));
    } finally {
      setCurrentItemSelectedToToggle(undefined);
      if (modalType === ModalType.DISABLE) {
        setIsModalDisableOpen(false);
      } else if (modalType === ModalType.ENABLE) {
        setIsModalEnableOpen(false);
      }
    }
  };

  useEffect(() => {
    if (accounts) {
      const statusAccounts = accounts.map(
        (accItem: IAccountItem) => accItem.status
      );
      if (!isEqual(statusEnableObj, statusAccounts)) {
        setStatusEnableObj(statusAccounts);
      }
    }
  }, [accounts]);

  return (
    <>
      {currentItemEditing && (
        <AddEditAccountModal
          isModalVisible={!!currentItemEditing}
          handleCancel={() => {
            setCurrentItemEditing(undefined);
          }}
          handleOk={() => {
            setCurrentItemEditing(undefined);
            handleAfterEdit();
          }}
          formFromProps={{
            ...currentItemEditing,
            profile: {
              ...currentItemEditing?.profile,
              avatar: currentItemEditing?.profile?.avatar?.url,
              background: currentItemEditing?.profile?.background?.url,
            },
          }}
        />
      )}
      {currentItemChangePassword && (
        <ChangePasswordAccountModal
          isModalVisible={!!currentItemChangePassword}
          handleCancel={() => {
            setCurrentItemChangePassword(undefined);
          }}
          handleOk={() => {
            setCurrentItemChangePassword(undefined);
            handleAfterEdit();
          }}
          formFromProps={{
            ...currentItemChangePassword,
            profile: {
              ...currentItemChangePassword?.profile,
              avatar: currentItemChangePassword?.profile?.avatar?.url,
              background: currentItemChangePassword?.profile?.background?.url,
            },
          }}
        />
      )}
      <Modal
        title={t("modal.disableAccountTile")}
        visible={isModalDisableOpen}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
        onOk={() => handleOkStatus(ModalType.DISABLE)}
        onCancel={() => handleCancelStatus(ModalType.DISABLE)}
        maskClosable={false}
      >
        <p>
          {t("modal.disableAccountContent", {
            email: currentItemSelectedToToggle?.username,
          })}
        </p>
      </Modal>
      <Modal
        title={t("modal.enableAccountTitle")}
        visible={isModalEnableOpen}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
        onOk={() => handleOkStatus(ModalType.ENABLE)}
        onCancel={() => handleCancelStatus(ModalType.ENABLE)}
        maskClosable={false}
      >
        <p>
          {t("modal.enableAccountContent", {
            email: currentItemSelectedToToggle?.username,
          })}
        </p>
      </Modal>
      <Table
        locale={{ emptyText: () => <Empty description={t("common.noData")} /> }}
        rowKey="id"
        bordered
        dataSource={accounts || []}
        columns={columns as any}
        pagination={false}
        loading={loading}
        style={{ width: window.innerWidth * 0.8 }}
      />
    </>
  );
};

export default AccountList;
