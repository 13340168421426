import { Spin } from "antd";

interface IProps {
  isLoading: boolean;
}

export default function LoadingApi({ isLoading }: IProps) {
  return isLoading ? (
    <div className="spin-container">
      <Spin className="spin-loading-screen" size="large" />
    </div>
  ) : null;
}
