import styles from "./styles.module.scss";
import { Upload } from "antd";
import { UploaderType } from "constants/enum";
import ImgCrop from "antd-img-crop";

export interface IChangeItem {
  url: string;
  file: File;
}

interface IProps {
  type: UploaderType;
  handleChange({ url, file }: IChangeItem): void;
  children: any;
  aspect?: number;
}

export default function Uploader({
  type,
  children,
  handleChange,
  aspect = 1 / 1,
}: IProps) {
  let captureType: any;
  if (type === UploaderType.TAKE_PHOTO) {
    captureType = "environment";
  }

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onFileChange = (currentFile: any) => {
    getBase64(currentFile, (url: string) => {
      handleChange({ url, file: currentFile });
    });
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <ImgCrop
        aspect={aspect}
        rotate
        grid
        onModalOk={(file) => onFileChange(file)}
        maxZoom={9}
      >
        <Upload
          customRequest={() => null}
          className={styles.uploadCustom}
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          onChange={() => null}
          accept="image/x-png,image/jpeg"
          capture={captureType}
        >
          {children}
        </Upload>
      </ImgCrop>
    </div>
  );
}
