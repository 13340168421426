import { Button, DatePickerProps, DatePicker, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";
import DashboardTable from "./DashboardTable";
import useFilter from "utils/hooks/useFilter";
import { IFilter } from "constants/interface";
import { useQuery } from "react-query";
import { getDashboardData } from "api/dashboard";
import { useEffect, useState } from "react";
import { getFromToDate } from "utils/helper/processData";

import moment from "moment";
import localeConfig from "antd/es/locale/ja_JP";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import {
  convertToFullDate,
  convertToFullDateJapanese,
} from "utils/helper/format";

const defaultFilter: IFilter = {
  page_index: 1,
  page_size: 5,
};

export default function Dashboard() {
  const currentKey = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const { filter, handleFilterChange } = useFilter(defaultFilter);
  const [dateParams, setDateParams] = useState<{
    from?: string;
    to?: string;
  }>({
    from: undefined,
    to: undefined,
  });
  const {
    data: dashboardData,
    isFetching: loadingListAudioHistory,
    refetch,
  } = useQuery(["dashboardData", filter], () => getDashboardData(filter));
  const handleReload = () => {
    refetch();
  };
  const onChangeRange: DatePickerProps["onChange"] = (date) => {
    const dateProcessed = getFromToDate({ date });
    setDateParams(getFromToDate({ date }));
    if (dateProcessed.from && dateProcessed.to) {
      handleFilterChange({
        from: dateProcessed.from,
        to: dateProcessed.to,
      });
    }
  };
  const handleFormatTime = (time?: string) => {
    if (currentKey === "en") {
      return convertToFullDate(time);
    }
    return convertToFullDateJapanese(time);
  };

  useEffect(() => {
    const date = moment();
    setDateParams(getFromToDate({ date }));
  }, []);

  return (
    <div className={styles.container}>
      <h2>{t("dashboard.topListenedAudios")}</h2>
      <div className={styles.filterContainer}>
        <div>
          <ConfigProvider locale={localeConfig}>
            <DatePicker
              locale={locale}
              onChange={onChangeRange}
              picker="month"
              format={"YYYY年MM月"}
              placeholder={t("placeholder.monthPicker")}
            />
          </ConfigProvider>
          {dateParams?.from && dateParams?.to && (
            <span style={{ marginLeft: 10 }}>
              <strong>
                {handleFormatTime(dateParams?.from)} 〜{" "}
                {handleFormatTime(dateParams?.to)}
              </strong>
            </span>
          )}
        </div>
        <Button icon={<ReloadOutlined />} onClick={handleReload}>
          {t("common.reload")}
        </Button>
      </div>
      <DashboardTable
        loading={loadingListAudioHistory}
        dashboard={dashboardData?.histories || []}
        pageIndex={filter.page_index || 1}
        pageSize={filter.page_size || 5}
      />
      <p />
    </div>
  );
}
