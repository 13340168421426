import { Pagination, PaginationProps } from "antd";
import { getLanguageEnvs, getLanguageKeys } from "api/language";
import { IFilter } from "constants/interface";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useFilter from "utils/hooks/useFilter";

import LanguageFilter from "./LanguageFilter";
import LanguageTable from "./LanguageTable";
import LanguageUpload from "./LanguageUpload";
import styles from "./styles.module.scss";

const defaultFilter: IFilter = {
  page_index: 1,
  page_size: 10,
};

export default function Language() {
  const [t] = useTranslation();

  const { filter, handleFilterChange, handlePageChange, handleSearch } =
    useFilter(defaultFilter);
  const { data: languageKeys, isLoading: loadingLanguageKeys } = useQuery(
    ["languageKeys", filter],
    () => getLanguageKeys(filter)
  );
  const { data: languageEnvs } = useQuery("languageEnvs", getLanguageEnvs);

  const showTotal: PaginationProps["showTotal"] = (total) =>
    t("pagination.total", { total });

  return (
    <div className={styles.container}>
      <h2>{t("language.title")}</h2>
      <div className={styles.action}>
        <LanguageFilter
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          languageEnvs={languageEnvs?.data}
        />
        <LanguageUpload languageEnvs={languageEnvs?.data} />
      </div>
      <LanguageTable
        loading={loadingLanguageKeys}
        languages={languageKeys?.data || []}
        pageIndex={filter.page_index || 1}
        pageSize={filter.page_size || 10}
      />
      <p />
      <Pagination
        current={filter.page_index}
        total={languageKeys?.pagination?.count}
        pageSize={filter.page_size}
        onChange={handlePageChange}
        showSizeChanger={false}
        showTotal={showTotal}
      />
    </div>
  );
}
