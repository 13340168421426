import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { IFilter, ILanguageEnv } from "constants/interface";
import { EnvType } from "constants/enum";
import styles from "./styles.module.scss";

const { Option } = Select;

interface IProps {
  filter: IFilter;
  handleFilterChange: (changeValue: IFilter) => void;
  handleSearch: any;
  languageEnvs: ILanguageEnv[];
}

export default function LanguageFilter({
  filter,
  handleSearch,
  languageEnvs,
}: IProps) {
  const [t] = useTranslation();

  return (
    <div className={styles.filterContainer}>
      <Input.Search
        placeholder={t("placeholder.keyword")}
        onChange={handleSearch?.keywordSearch}
      />
      <Select
        mode="multiple"
        className={styles.select}
        placeholder={t("placeholder.env")}
        onChange={handleSearch?.envSearch}
        allowClear
      >
        {languageEnvs?.map((le: ILanguageEnv) => (
          <Option value={le.id} key={le.id}>
            {le.name}
          </Option>
        ))}
      </Select>
    </div>
  );
}
