import { sendGet, sendPost, sendPut } from "./axios";

export const getLanguages = (params: any) =>
  sendGet("/cms/i18n/languages", params);
export const getLanguageFlags = () => sendGet("/cms/i18n/language_flags");
export const getLanguageKeys = (params: any) =>
  sendGet("/cms/i18n/language_keys", params);
export const getLanguageEnvs = () => sendGet("/cms/i18n/language_envs");
export const updateLanguageKey = (payload: any, id?: number) =>
  sendPut(`/cms/i18n/language_keys/${id}`, payload);
export const importLanguageKeys = (payload: any) =>
  sendPost("/cms/i18n/language_keys/import", payload);
