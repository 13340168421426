import Accounts from "pages/Accounts";
import Audios from "pages/Audios";
import Dashboard from "pages/Dashboard";
import Company from "pages/Settings/Company";
import Hashtags from "pages/Settings/Hashtags";
import Terms from "pages/Settings/Terms";
import Tasks from "pages/Tasks";
import { lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";
import Language from "pages/System/Language";
import LoadingApi from "components/LoadingApi";
import { setResourceI18n } from "i18n/i18n";
import useLanguageFlag from "utils/hooks/useLanguageFlag";

const Login = lazy(() => import("pages/Login"));
const Chats = lazy(() => import("pages/Chats"));

export default function AppWrapper() {
  const [ready, setReady] = useState(false);

  const { languageFlags } = useLanguageFlag();

  useEffect(() => {
    setResourceI18n().then(() => {
      setReady(true);
    });
  }, []);

  if (!ready && !!!languageFlags) return <LoadingApi isLoading={true} />;

  return (
    <div className="root-wrapper">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<AuthWrapper />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route
            path="/accounts/:accountId/conversations/:conversationId"
            element={<Chats />}
          />
          <Route path="/audios" element={<Audios />} />
          <Route path="/settings/hashtags" element={<Hashtags />} />
          <Route path="/settings/terms" element={<Terms />} />
          <Route path="/settings/company" element={<Company />} />
          <Route path="/system/language" element={<Language />} />
        </Route>
      </Routes>
    </div>
  );
}
