import React from "react";
import Cookies from "js-cookie";
import PageHeader from "components/PageHeader";
import SideNav from "components/SideNav";
import styles from "./styles.module.scss";
import { Navigate, useOutlet } from "react-router-dom";

export default function PageWrapper() {
  const outlet = useOutlet();
  const isAuthenticated = !!Cookies.get("access_token");

  if (!isAuthenticated) return <Navigate to="/login" />;
  return (
    <div className={styles.pageWrapper}>
      <SideNav />
      <div className={styles.mainWrapper}>
        <PageHeader />
        <div className={styles.pageContent}>{outlet}</div>
      </div>
    </div>
  );
}
