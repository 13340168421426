import { Table, Button, Tooltip, message, Empty } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";

import { IHashtag } from "constants/interface";
import { useState } from "react";
import HashtagModal from "./HashtagModal";
import { UpdateType } from "constants/enum";
import ModalConfirm from "components/ModalConfirm";
import { deleteHashtag } from "api/hashtags";

interface IProps {
  hashtags: IHashtag[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
}

export default function HashtagTable({
  hashtags,
  loading,
  pageIndex,
  pageSize,
}: IProps) {
  const { t } = useTranslation();
  const [currentHashtag, setCurrentHashtag] = useState<IHashtag>();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: delHashtag, isLoading: loadingDelHashtag } = useMutation(
    (id?: number) => deleteHashtag(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listHashtag");
        setIsVisibleModalConfirm(false);
        message.success(t("form.deleteHashtagSuccess"));
      },
    }
  );

  const columns: ColumnsType<IHashtag> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      render: (value: any, record: IHashtag, index: number) => (
        <span>{(pageIndex - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: t("table.name"),
      dataIndex: "name",
    },
    {
      title: t("table.owner"),
      dataIndex: "streamer",
    },
    {
      title: t("table.amountUsed"),
      dataIndex: "total_number_of_uses",
    },
    {
      title: t("table.action"),
      render: (text: any, record: IHashtag) => {
        return (
          <>
            <Tooltip placement="topLeft" title={t("tooltip.editHashtag")}>
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setCurrentHashtag(record);
                  setIsVisibleModal(true);
                }}
              ></Button>
            </Tooltip>
            &nbsp;
            {record.streamer ? (
              <Tooltip placement="topLeft" title={t("tooltip.deleteHashtag")}>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setCurrentHashtag(record);
                    setIsVisibleModalConfirm(true);
                  }}
                ></Button>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];
  const handleDeleteHashtag = () => {
    delHashtag(currentHashtag?.id);
  };

  return (
    <>
      <Table
        locale={{ emptyText: () => <Empty description={t("common.noData")} /> }}
        rowKey="id"
        dataSource={hashtags || []}
        columns={columns}
        pagination={false}
        loading={loading}
        bordered
      />
      {isVisibleModal && (
        <HashtagModal
          isVisibleModal={isVisibleModal}
          setIsVisibleModal={setIsVisibleModal}
          type={UpdateType.UPDATE}
          data={currentHashtag}
        />
      )}
      {isVisibleModalConfirm && (
        <ModalConfirm
          isVisibleModal={isVisibleModalConfirm}
          content={t("modal.warningDeleteText", {
            name: currentHashtag?.name,
          })}
          onOk={handleDeleteHashtag}
          onCancel={() => setIsVisibleModalConfirm(false)}
          loading={loadingDelHashtag}
        />
      )}
    </>
  );
}
