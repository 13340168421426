import { getLanguageFlags } from "api/language";
import { useQuery } from "react-query";

export default function useLanguageFlag() {
  const { data: languageFlags, isFetching: fetchingLanguageFlags } = useQuery(
    "languageFlags",
    getLanguageFlags
  );

  return { languageFlags, fetchingLanguageFlags };
}
