import { Modal, Form, Button, message, Select, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

import { LanguageType } from "constants/enum";
import { ILanguageEnv } from "constants/interface";
import styles from "./styles.module.scss";
import { RcFile } from "antd/lib/upload";
import { getLanguageEnvs, importLanguageKeys } from "api/language";
import classNames from "classnames";
import { handleDownloadLanguage } from "utils/helper";

const { Option } = Select;

interface IProps {
  isVisibleModal: boolean;
  setIsVisibleModal: Function;
}

export default function LanguageUploadModal({
  isVisibleModal,
  setIsVisibleModal,
}: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [curFile, setCurFile] = useState();

  const { data: languageEnvs } = useQuery("languageEnvs", getLanguageEnvs);
  const { mutate: postLanguageKeys, isLoading: loadingPostLanguageKeys } =
    useMutation((payload: any) => importLanguageKeys(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries("languageKeys");
        message.success(t("form.uploadLanguageKeysSuccess"));
        setIsVisibleModal(false);
      },
    });

  const handleSubmit = (payload: any) => {
    if (curFile) {
      postLanguageKeys({
        environment: payload?.environment,
        code: payload?.code,
        json_file: curFile,
      });
    }
  };
  const beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    if (file.type !== "application/json") {
      message.error(t("validate.invalidFormatFile"));
      return false;
    }
    setFileList((pre) => [file]);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setCurFile(JSON.parse(e.target.result));
    };
    reader.readAsText(file);
    return false;
  };
  const onRemove = (file: any) => {
    setFileList([]);
  };

  return (
    <Modal
      title={t("modal.uploadLanguageTitle")}
      visible={isVisibleModal}
      onCancel={() => setIsVisibleModal(false)}
      footer={null}
      className={styles.languageModal}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleSubmit}>
        {/* <div className="d-flex mb-20">
          <div className="mw-150">
            {t("form.fileLanguageExample")}
            {": "}
          </div>
          <Select
            className={classNames(styles.selectEnvForm)}
            placeholder={
              <div className={styles.placeholder}>
                <DownloadOutlined />
                <span className="ml-5">{t("placeholder.selectEnv")}</span>
              </div>
            }
            onChange={(value: any) => handleDownloadLanguage(value)}
            allowClear
          >
            {languageEnvs?.data?.map((le: ILanguageEnv) => (
              <Option value={le.name} key={le.id}>
                {le.name}
              </Option>
            ))}
          </Select>
        </div> */}
        <Form.Item
          name="key"
          label={t("form.keyLanguageLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload
            accept=".json"
            beforeUpload={beforeUpload}
            multiple={false}
            fileList={fileList}
            onRemove={onRemove}
          >
            <Button icon={<UploadOutlined />}>{t("form.uploadFile")}</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="environment"
          label={t("form.envLanguageLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder={t("placeholder.env")}>
            {languageEnvs?.data?.map((le: ILanguageEnv) => (
              <Option value={le.id} key={le.id}>
                {le.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="code"
          label={t("form.languageTypeLabel")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder={t("placeholder.languageType")}>
            <Option value={LanguageType.JA}>{LanguageType.JA}</Option>
            <Option value={LanguageType.EN}>{LanguageType.EN}</Option>
          </Select>
        </Form.Item>
        <div className={styles.footerModal}>
          <Button onClick={() => setIsVisibleModal(false)}>
            {t("form.cancelBtn")}
          </Button>
          <Button
            onClick={() => {
              form.submit();
            }}
            className="ml-10"
            type="primary"
            disabled={fileList?.length === 0}
          >
            {t("form.editBtn")}
          </Button>
        </div>
        <div className="textRequired">{t("form.textRequired")}</div>
      </Form>
    </Modal>
  );
}
