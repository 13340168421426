import Axios from "axios";
import configs from "constants/config";
import { handleErrorMessage } from "i18n";
import Cookies from "js-cookie";
import { logout } from "utils/helper/authentication";

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    // eslint-disable-next-line no-param-reassign
    const access_token = Cookies.get("access_token");
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    const originalConfig = error.config;
    if (error.response.status !== 401) {
      handleErrorMessage(error);
      return Promise.reject(error);
    }
    const refresh_token = Cookies.get("refresh_token");
    if (!refresh_token) {
      logout();
      return Promise.reject(error);
    }
    return Axios.post(`${configs.API_DOMAIN}/cms/auth/request-access-token`, {
      refresh_token,
    })
      .then((res: any) => {
        if (res.status === 200) {
          const data = res.data.data;
          Cookies.set("access_token", data.access_token);
          originalConfig.headers.Authorization = `Bearer ${data.access_token}`;
          return Axios(originalConfig);
        } else {
          logout();
          return Promise.reject(error);
        }
      })
      .catch(() => {
        logout();
        return Promise.reject(error);
      });
  }
);

export const sendGet = (url: string, params?: any) =>
  axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance
    .post(url, params, { params: queryParams })
    .then((res) => res.data);
export const sendPut = (url: string, params?: any) =>
  axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) =>
  axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) =>
  axiosInstance.delete(url, { params }).then((res) => res.data);
