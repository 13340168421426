import { Table, message, Switch, Tag, Tooltip, Button, Empty } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { useMutation, useQueryClient } from "react-query";

import { IAudio, IHashtag } from "constants/interface";
import { useState } from "react";
import { StatusAudio, StatusType } from "constants/enum";
import styles from "./styles.module.scss";
import AudioModal from "./AudioModal";
import { updateAudioHashtagAPI, updateStatus } from "api/audio";
import { EditOutlined } from "@ant-design/icons";
import AudioEditHashtagsModal from "./components/AudioEditHashtagsModal";

interface IProps {
  audios: IAudio[];
  loading: boolean;
  pageIndex: number;
  pageSize: number;
  handleAfterEdit?(inputs: Array<string>): void;
}

export interface IAudioItem {
  id: number;
  audience_count: number;
  background: string;
  channel_name: string;
  hashtags: Array<{
    id: number;
    name: string;
    created_by: number;
    number_of_uses: number;
  }>;
  resource_id: number;
  sid: number;
  status: StatusType;
  stream_date: string;
  stream_video_urls: Array<string>;
  streamer: number;
  streamer_id: number;
}

export default function AudioTable({
  audios,
  loading,
  pageIndex,
  pageSize,
  handleAfterEdit,
}: IProps) {
  const { t } = useTranslation();
  const [currentAudio, setCurrentAudio] = useState<IAudio>();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const queryClient = useQueryClient();
  const [currentItemEditing, setCurrentItemEditing] = useState<
    IAudioItem | undefined
  >(undefined);

  const { mutate: putStatus, isLoading: loadingPutStatus } = useMutation(
    (payload: any) => updateStatus(payload?.data, payload?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listAudioHistory");
        message.success(t("form.updateStatusSuccess"));
      },
    }
  );

  const { mutate: putAudioHashtags } = useMutation(
    (payload: any) => updateAudioHashtagAPI(payload?.data, payload?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listAudioHistory");
        message.success(t("form.updateHashtagSuccess"));
      },
    }
  );

  const handleToggleActive = (checked: boolean, audio: IAudio) => {
    putStatus({
      data: {
        status: checked ? StatusAudio.ENABLE : StatusAudio.DISABLE,
      },
      id: audio.id,
    });
  };

  const columns: ColumnsType<IAudio> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      render: (value: any, record: IAudio, index: number) => (
        <span>{(pageIndex - 1) * pageSize + index + 1}</span>
      ),
      width: "5%",
    },
    {
      title: t("table.audio"),
      dataIndex: "audio",
      render: (value: any, record: IAudio, index: number) => (
        <div
          onClick={() => {
            setCurrentAudio(record);
            setIsVisibleModal(true);
          }}
          className={styles.listenIcon}
        >
          {record.background?.thumb?.url ? (
            <img src={record.background?.thumb?.url} alt="Background" />
          ) : (
            <div className={styles.imgUndefined} />
          )}
        </div>
      ),
      width: "12%",
    },
    {
      title: t("table.audienceCount"),
      dataIndex: "audience_count",
      width: "10%",
    },
    {
      title: t("table.hashtags"),
      dataIndex: "hashtags",
      render: (value: any, record: IAudio, index: number) => (
        <>
          {record.hashtags.map((hashtag: IHashtag, hashtagIndex: number) => (
            <Tag
              key={`${hashtagIndex}`}
              style={{ fontSize: 14 }}
            >{`#${hashtag.name}`}</Tag>
          ))}
        </>
      ),
    },
    {
      title: t("table.owner"),
      dataIndex: "streamer",
    },
    {
      title: t("table.createdAt"),
      dataIndex: "createdAt",
      render: (value: any, record: IAudio, index: number) => (
        <span>{record.stream_date}</span>
      ),
    },
    {
      title: t("table.status"),
      render: (text: any, record: IAudio) => {
        return (
          <>
            <Switch
              onChange={(checked: boolean) => {
                handleToggleActive(checked, record);
              }}
              checked={record.status === StatusAudio.ENABLE}
              disabled={loadingPutStatus}
            />
          </>
        );
      },
      width: "8%",
    },
    {
      title: t("table.action"),
      render: (text: any, record: any) => {
        return (
          <>
            <Tooltip placement="topLeft" title={t("table.editHashtags")}>
              <Button
                onClick={() => {
                  setCurrentItemEditing(record);
                }}
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>
          </>
        );
      },
      width: "12%",
    },
  ];

  return (
    <>
      <Table
        locale={{ emptyText: () => <Empty description={t("common.noData")} /> }}
        rowKey="id"
        dataSource={audios || []}
        columns={columns}
        pagination={false}
        loading={loading}
        bordered
      />
      {currentItemEditing && (
        <AudioEditHashtagsModal
          isModalVisible={!!currentItemEditing}
          onCancel={() => {
            setCurrentItemEditing(undefined);
          }}
          onOk={(inputs: Array<string>) => {
            putAudioHashtags({
              data: {
                streamer_id: currentItemEditing?.streamer_id,
                hashtags: inputs,
              },
              id: currentItemEditing?.id,
            });
            setCurrentItemEditing(undefined);
            if (handleAfterEdit) {
              handleAfterEdit(inputs);
            }
          }}
          itemFromProps={currentItemEditing}
        />
      )}
      {isVisibleModal && (
        <AudioModal
          isVisibleModal={isVisibleModal}
          onCancel={() => setIsVisibleModal(false)}
        >
          <div className={styles.reactPlayer}>
            <audio src={currentAudio?.stream_video_urls[0]} controls autoPlay />
          </div>
        </AudioModal>
      )}
    </>
  );
}
